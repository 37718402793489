<template>
  <el-dialog
    :visible="visible"
    :title="isAdd ? '新建试卷库' : '修改试卷库'"
    width="484"
    class="yt-dialog yt-dialog-default"
    @close="close"
    @on-cancel="close"
    :mask-closable="false"
  >
    <el-form ref="paperBankForm" :model="paperBankForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="名称" prop="name">
        <el-input v-model="paperBankForm.name" placeholder="请输入试卷库名称" />
        <!--        <div v-if="!ruleValidate.name.error" class="form-tip">不超过30个字</div>-->
      </el-form-item>
      <el-form-item label="封面" prop="cover">
        <div class="custom-upload" :class="{ uploaded: uploadSuccess }">
          <Upload
            ref="upload"
            :show-upload-list="false"
            :on-success="handleUploadCoverSuccess"
            :on-progress="handleUploadProgress"
            :before-upload="beforeUpload"
            :on-exceeded-size="handleExceededSize"
            :data="uploadParams"
            :format="['jpg', 'jpeg', 'png']"
            :max-size="1024"
            type="drag"
            :action="uploadUrl"
          >
            <Progress v-if="isUploading" :percent="percentage" hide-info />
            <div class="upload">
              <img v-if="!isUploading && uploadSuccess" :src="paperBankForm.photoUrl" alt="" />
              <svg v-if="!uploadSuccess" class="icon" aria-hidden="true">
                <use xlink:href="#icon-xinzeng" />
              </svg>
            </div>
          </Upload>
          <div class="form-tip">
            支持 jpg/gif/png 格式，不超过 10M
          </div>
        </div>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input type="textarea" v-model="paperBankForm.description" :rows="12" placeholder="请输入试卷库描述" />
        <div class="form-tip" v-if="!ruleValidate.description.error">
          不超过200个字
        </div>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-Button @click="close" class="button">取消</el-Button>
      <el-Button type="primary" class="button" @click="dealQuestionBank('paperBankForm')" :loading="loading">确定 </el-Button>
    </div>
  </el-dialog>
</template>
<script>
import paperApi from '@api/paper'
import ossApi from '@api/oss'
import config from '../../../../config/config'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    paperBank: {
      type: Object
    },
    categoryId: {
      type: Number
    }
  },
  data: function() {
    const that = this

    return {
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      percentage: 0,
      uploadUrl: '',
      serveLoca: '',
      uploadSuccess: false,
      isUploading: false,
      visible: this.value,
      loading: false,
      paperBankForm: {
        name: this.isAdd ? '' : this.paperBank.name,
        description: this.isAdd ? '' : this.paperBank.description,
        photoUrl: this.isAdd ? '' : this.paperBank.photoUrl
      },
      ruleValidate: {
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.trim() === '') {
              that.ruleValidate.name.error = true
              callback(new Error('试卷库名称不为空'))
            } else if (value.length > 30) {
              that.ruleValidate.name.error = true
              callback(new Error('试卷库名称不超过30个字'))
            } else {
              that.ruleValidate.name.error = false
              callback()
            }
          }
        },
        description: {
          type: 'string',
          error: false,
          validator: (rule, value, callback) => {
            if (value.length > 200) {
              that.ruleValidate.description.error = true
              callback(new Error('试卷库描述不超过 200 字'))
            } else {
              that.ruleValidate.description.error = false
              callback()
            }
          }
        }
      }
    }
  },
  methods: {
    handleUploadCoverSuccess(response, file) {
      this.uploadSuccess = true
      this.isUploading = false
      if (window.uploadUrl) {
        this.paperBankForm.photoUrl = this.serveLoca
      } else {
        this.paperBankForm.photoUrl = `${this.uploadUrl}/${this.uploadParams.key}`
      }
    },
    beforeUpload(file) {
      if (window.uploadUrl) {
        //
        let forData = new FormData()
        forData.append('file', file)
        ossApi.upCover(forData).then(res => {
          this.serveLoca = res.data.res
          this.handleUploadCoverSuccess()
        })
      } else {
        ossApi.upType().then(() => {
          ossApi.getQuestionBankCoverToken(new Date().valueOf(), file.name).then(res => {
            const data = res.res
            this.uploadUrl = `https://${data.host}`
            this.uploadParams.key = data.dir
            this.uploadParams.OSSAccessKeyId = data.accessId
            this.uploadParams.policy = data.policy
            this.uploadParams.Signature = data.signature
            let formData = new FormData()
            formData.append('key', this.uploadParams.key)
            formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
            formData.append('policy', this.uploadParams.policy)
            formData.append('Signature', this.uploadParams.Signature)
            formData.append('file', file)
            fetch(`https://${data.host}`, {
              method: 'POST',
              body: formData
            }).then(data => {
              this.handleUploadCoverSuccess()
            })
          })
        })
      }
      return false
    },
    handleExceededSize() {
      this.$message.error('图片大小不超过 10M')
    },
    handleUploadProgress(event, file) {
      this.isUploading = true
      this.percentage = file.percentage
    },
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
      this.isUploading = false
      this.uploadSuccess = false
    },
    //判断是创建试卷库还是修改试卷库
    dealQuestionBank(name) {
      this.$refs[name].validate(valid => {
        if (!valid) {
          return
        }
        this.loading = true
        let paperBankInfo = Object.assign(this.paperBankForm)
        if (!this.isAdd) {
          //修改需要多添加一项id
          paperBankInfo.id = this.paperBank.paperBankId
        }
        paperBankInfo.categoryId = this.categoryId
        let promise = this.isAdd ? paperApi.addPaperBank(paperBankInfo) : paperApi.modifyPaperBank(paperBankInfo)

        promise
          .then(res => {
            if (res.res === 1) {
              this.$message.success('修改成功')
            }
            if (res.code === 0 && this.isAdd) {
              this.$message.success('新建成功')
            }
            this.close()
            this.isAdd && this.$refs[name].resetFields()
            this.$emit('on-success', Object.assign(this.paperBank || {}, paperBankInfo))
          })
          .finally(() => {
            this.loading = false
            this.isUploading = false
            this.uploadSuccess = false
          })
      })
    }
  },
  watch: {
    value(val) {
      this.visible = val
      this.paperBankForm = {
        name: this.isAdd ? '' : this.paperBank.name,
        description: this.isAdd ? '' : this.paperBank.description,
        photoUrl: this.isAdd ? '' : this.paperBank.photoUrl
      }
      if (!this.isAdd) {
        this.uploadSuccess = !!this.paperBank.photoUrl
      }
    }
  }
}
</script>
<style lang="less" scoped>
.paper-modal ::v-deep .ivu-modal {
  .ivu-modal-body {
    .custom-upload {
      .ivu-upload-drag {
        width: 124px;

        img {
          width: 124px;
          height: 90px;
        }
      }

      &.uploaded {
        .ivu-upload-drag {
          border: none;
          display: inline;

          &:hover {
            border: none;
          }
        }
      }
    }

    .upload {
      width: 124px;
      height: 90px;
      font-size: 60px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #b8c2d1;
      }
    }
  }
}
::v-deep .yt-dialog-form {
  text-align: left;
}
::v-deep .ivu-upload-drag {
  width: 350px;
}
</style>
