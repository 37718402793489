<template>
  <el-dialog
    :visible.sync="visible"
    :title="'移动试卷'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="试卷库" prop="paperBankId">
        <el-select v-model="formData.paperBankId" placeholder="请选择">
          <el-option v-for="item in paperOptions" :key="item.paperBankId" :label="item.name" :value="item.paperBankId"> </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="create">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import paperApi from '@/api/paper'
import paperBankApi from '@/api/paperBank'
export default {
  name: 'TestPaperMoveDialog',
  data() {
    return {
      visible: false,
      paperOptions: [],
      formData: {
        paperBankId: '',
        paperId: ''
      },
      ruleValidate: {
        paperBankId: [{ required: true, message: '请选择试卷库', trigger: 'change' }]
      },
      loading: false
    }
  },
  methods: {
    getPaperBank(id) {
      paperBankApi.getPaperBankList(id).then(res => {
        this.paperOptions = res.res
      })
    },
    open(data) {
      this.formData.paperId = data.paperId
      this.getPaperBank(data.paperBankId)
      this.visible = true
    },
    create() {
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          let payload = {
            paperBankId: this.formData.paperBankId,
            paperId: this.formData.paperId
          }
          paperApi
            .moveTestPaper(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('移动成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
