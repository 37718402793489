<template>
  <el-dialog
    :visible.sync="visible"
    :title="'复制试卷'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="试卷库分类">
        <el-select placeholder="请选择" v-model="formData.categoryId" @change="getPaperBank()">
          <el-option v-for="item in categoryOptions" :key="item.categoryId" :label="item.name" :value="item.categoryId"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试卷库" prop="paperBankId">
        <el-select v-model="formData.paperBankId" placeholder="请选择">
          <el-option v-for="item in paperOptions" :key="item.paperBankId" :label="item.name" :value="item.paperBankId"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试卷名称" prop="paperName">
        <el-input v-model="formData.paperName" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="create">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import paperApi from '@/api/paper'
import paperBankApi from '@/api/paperBank'
import paperBankCategoryApi from '@/api/paperBankCategory'

export default {
  name: 'PaperCopyDialog',
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('试卷名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      categoryOptions: [],
      paperOptions: [],
      formData: {
        paperBankId: '',
        paperId: '',
        paperName: '',
        categoryId: ''
      },
      ruleValidate: {
        paperName: [
          { required: true, message: '试卷名称不能为空' },
          { max: 20, message: '试卷名称长度不能超过20个字符' },
          { min: 1, message: '试卷名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ],
        paperBankId: [{ required: true, message: '请选择试卷库', trigger: 'change' }]
      },
      loading: false
    }
  },
  methods: {
    getPaperBank() {
      let payload = {
        categoryId: this.formData.categoryId
      }
      paperBankApi.getPaperBankByCategory(payload).then(res => {
        this.paperOptions = res.res
      })
    },
    open(data) {
      this.getAllCategory()
      this.getPaperBank()
      this.visible = true
      this.formData.paperId = data.paperId
    },
    getAllCategory() {
      paperBankCategoryApi.getAllPaperBank().then(res => {
        this.categoryOptions = res.res
      })
    },
    create() {
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          let payload = {
            paperBankId: this.formData.paperBankId,
            paperId: this.formData.paperId,
            paperName: this.formData.paperName
          }
          paperApi
            .copyPaper(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('复制成功')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.formData.categoryId = ''
      this.formData.paperBankId = ''
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
