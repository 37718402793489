<template>
  <div class="paper-bank-card">
    <div class="img" :style="{ backgroundImage: `url(${paperBank.photoUrl || ''})` }" @click="handleOnclick"></div>
    <div class="content">
      <div class="name">
        <span :title="paperBank.name" class="ellpS">{{ paperBank.name }}</span>
      </div>
      <div class="desc">
        <span :title="paperBank.description" class="ellpS">{{ paperBank.description }}</span>
      </div>
    </div>
    <div class="footer">
      <div class="user-info">
        <div class="img" :style="{ backgroundImage: paperBank.creatorImageURL }"></div>
        <span class="user-name">{{ paperBank.creatorName }}</span>
        <span class="create-time">创建于{{ $relativeTimeFilter(new Date(paperBank.createTime)) }}</span>
      </div>

      <!--操作下拉-->
      <Dropdown placement="bottom-end" :transfer="true" trigger="click" @on-click="handleOperationClick">
        <div class="operation">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-gengduo"></use>
          </svg>
        </div>
        <DropdownMenu slot="list">
          <DropdownItem name="edit">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bianji"></use>
            </svg>
            编辑
          </DropdownItem>
          <DropdownItem name="delete">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shanchu"></use>
            </svg>
            删除
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>

    <PaperBankModal
      :is-add="false"
      v-model="modifyPaperBankModal"
      :paper-bank="paperBank"
      @on-success="res => handleOperationSuccess('on-change', res)"
    />
    <DeleteModal v-model="deletePaperBankModal" :title="`确认删除试卷库“${paperBank.name}”吗？`" :loading="deleteLoading" @on-ok="deletePaperBank">
      <span class="error">删除后该试卷库下的试卷将消失， 您还要继续吗？</span>
    </DeleteModal>
  </div>
</template>
<script>
import PaperBankModal from './PaperBankModal'
import DeleteModal from '../../../../components/common/DeleteModal'
import paperApi from '@api/paper'

export default {
  props: {
    paperBank: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'default'
    },
    categoryId: {
      type: Number
    }
  },
  data: function() {
    return {
      modifyPaperBankModal: false,
      deletePaperBankModal: false,
      deleteLoading: false
    }
  },
  components: {
    PaperBankModal,
    DeleteModal
  },
  methods: {
    handleOnclick() {
      this.$emit('click')
    },
    //判断修改或者删除
    handleOperationClick(name) {
      name === 'edit' ? (this.modifyPaperBankModal = true) : (this.deletePaperBankModal = true)
    },
    handleOperationSuccess(name, res) {
      this.$emit(name, res)
    },
    //删除试卷库
    deletePaperBank() {
      if (this.categoryId === 0) {
        this.deleteLoading = true
        paperApi
          .deletePaperBank(this.paperBank.paperBankId)
          .then(() => {
            this.deletePaperBankModal = false
            this.$message.success('删除试卷库成功')
            this.handleOperationSuccess('on-delete', this.paperBank)
          })
          .finally(() => {
            this.deleteLoading = false
          })
      } else {
        this.deleteLoading = true
        paperApi
          .deleteCourse(this.paperBank.paperBankId)
          .then(() => {
            this.deletePaperBankModal = false
            this.$message.success('删除试卷库成功')
            this.handleOperationSuccess('on-delete', this.paperBank)
          })
          .finally(() => {
            this.deleteLoading = false
          })
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';

.paper-bank-card {
  width: 310px;
  height: 350px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
  display: flex;
  flex-direction: column;

  .img {
    width: 100%;
    height: 208px;
    background-color: #ebebeb;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .content {
    padding: 20px;
    flex: 1 0 auto;

    .name,
    .desc {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ellpS {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-size: 18px;
      color: #2b343d;
      position: relative;
      padding-right: 20px;

      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .desc {
      font-size: 16px;
      color: #bdc3cb;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e5e7ea;

    .user-info {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      padding: 10px 10px 10px 20px;

      .img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ebebeb;
        background-size: contain;
      }

      .user-name {
        color: @font-color-content;
        margin-left: 10px;
      }

      .create-time {
        margin-left: 10px;
        color: #bdc3cb;
      }
    }

    .operation {
      font-size: 20px;
      color: #bdc3cb;
      cursor: pointer;
      padding: 10px 20px;
      transition: all @default-transition;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

::v-deep .ivu-dropdown-item {
  color: @font-color-content;
}

.error {
  color: @error-color;
}
</style>
