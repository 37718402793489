import config from '../config/config'
import { axios } from './index'

const autoPaperRuleUrl = config.baseUrl + '/paper/api/v1/auto/paper/rule'

export default {
  //导出自动出卷出题配置
  downloadRule(payload) {
    return axios.get(`${autoPaperRuleUrl}/download?paperId=${payload}`, {
      responseType: 'blob'
    })
  }
}
